@media (max-width: 768px) {
  .ant-anchor-link {
    padding-left: 4% !important;
    padding-right: 4% !important;
    width: 25% !important;
    text-align: center !important;
  }
}
@media (min-width: 769px) {
  .ant-anchor-link {
    padding-left: 30px !important;
    padding-right: 30px !important;
    /* margin-left: 10px; */
    width: 120px !important;
    text-align: center !important;
  }
}

/* .ant-anchor div:first-of-type {
  border-right: 2px solid #e4e1e1 !important;
} */
