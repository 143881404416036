@media screen and (max-width: 768px) {
  .carouselContent {
    height: 390px;
    background-image: url(https://img.cbnlive.cn/cbnlive/www/image/bg.png);
    background-position: 30%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }
  .contentLeft {
    /* flex: 1; */
    margin-left: 10px;
    margin-top: 70px;
    z-index: 1;
  }
  .h1 {
    width: 200px;
    height: 26px;
    font-size: 26px;
    font-family: Manrope-SemiBold, Manrope;
    font-weight: 600;
    color: #000000;
    line-height: 26px;
    margin-top: 50px;
  }
  .h2 {
    width: 100%;
    min-width: 200px;
    height: 11px;
    font-size: 14px;
    font-family: Manrope-Medium, Manrope;
    font-weight: 500;
    color: #333333;
    line-height: 11px;
    margin-top: 10px;
    white-space: nowrap;
  }
  .text {
    width: 200px;
    height: 24px;
    font-size: 8px;
    font-family: Manrope-Medium, Manrope;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    margin-top: 17px;
  }
  .contentRight {
    flex: 1;
    margin-left: -170px;
  }
  .img {
    margin-top: 40px;
  }
}

@media (min-width: 769px) {
  .carouselContent {
    height: 700px;
    overflow: hidden;
  }
  .contentLeft {
    z-index: 1;
  }
  .contentText {
    margin-bottom: 98px;
  }
  .h1 {
    width: 415px;
    height: 54px;
    font-size: 54px;
    font-family: Manrope-ExtraBold, Manrope;
    font-weight: 800;
    color: #000000;
    line-height: 54px;
    margin: 160px 0 0 0;
    padding: 0;
  }
  .h2 {
    width: 216px;
    height: 24px;
    font-size: 24px;
    font-family: Manrope-Medium, Manrope;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    margin: 20px 0 40px 0;
    padding: 0;
  }
  .text {
    width: 100%;
    max-width: 450px;
    height: 48px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
}

@media (min-width: 769px) and (max-width: 1100px) {
  .img {
    margin-left: -100px;
    opacity: 0.6;
  }
}
