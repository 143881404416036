.supplier {
  margin: 0 auto;
  padding: 50px 300px;
}
@media screen and (max-width: 767px) {
  /* 这里是针对手机端的样式 */
  .supplier h1 {
    width: 100%;
  }
  .supplier h2 {
    width: 100%;
  }
  .supplier p {
    width: 100%;
  }
  .supplier {
    width: 90%;
    margin: 20px auto;
  }
}
