.item {
  margin-top: 50px;
  text-align: left;
}

.title {
  line-height: 14px;
}

.content {
  line-height: 24px;
  /* height: 24px; */
  min-width: 200px;
  max-width: 250px;
  font-size: 12px;
  font-weight: 400;
}
