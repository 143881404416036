@media (max-width: 768px) {
  .main {
    margin-top: 40px;
  }
  .layout {
    padding: 0 10px;
  }
  .marginBottom {
    margin-bottom: 20px;
  }

  .anchor {
    width: 100%;
    height: auto;
    position: fixed;
    top: 40px;
    left: 0;
    z-index: 2;
    background-color: #fff;
    border-bottom: 1px solid #e4e1e1;
  }
  .intro {
    width: 100%;
    height: 240px;
    background-image: url(https://img.cbnlive.cn/cbnlive/www/image/infobg.png);
    background-size: cover;
    background-position: center;
    display: block;
    text-align: center;
    color: #ffffff;
    margin-top: 70px;
  }
  .introTitle {
    font-size: 30px;
    margin-bottom: 8px;
    padding-top: 80px;
  }
  .introSubTitle {
    font-size: 13px;
    opacity: 0.8;
  }

  .desc {
    padding: 0 10px;
  }
  .descImg {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .culture {
    width: 100%;
    height: 280px;
    background-image: url(https://img.cbnlive.cn/cbnlive/www/image/culture.png);
    background-size: cover;
    background-position: center;
    text-align: center;
    margin-top: 20px;
  }
  .cultureContent {
    text-align: left;
  }

  .courseImg {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* pc */
@media (min-width: 769px) {
  .layout {
    max-width: 1440px;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .marginBottom {
    margin-bottom: 50px;
  }
  .intro {
    background-image: url(https://img.cbnlive.cn/cbnlive/www/image/infobg.png);
    width: 100%;
    height: 480px;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 100px;
  }
  .introTitle {
    width: 216px;
    height: 54px;
    font-size: 54px;
    font-family: Manrope-ExtraBold, Manrope;
    font-weight: 800;
    color: #ffffff;
    line-height: 54px;
    margin: 160px 0 20px 0;
  }
  .introSubTitle {
    width: 216px;
    height: 24px;
    font-size: 24px;
    font-family: Manrope-Medium, Manrope;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.82);
    line-height: 24px;
    margin: 0 0 222px 0;
  }

  .descImg {
    width: 710px;
    height: 682px;
    float: left;
    margin-right: 30px;
  }
  .descContent {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 27px;
    /* margin-top: -5px; */
  }

  .culture {
    background-image: url(https://img.cbnlive.cn/cbnlive/www/image/culture.png);
    height: 448px;
    width: 100%;
    background-size: cover;
    margin: 0 auto;
  }
  .cultureContent {
    height: 150px;
    font-size: 20px;
    font-family: Manrope-Regular, Manrope;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.82);
    line-height: 28px;
    margin: 0 auto;
  }

  .course {
    height: auto;
    background-color: #ffffff;
  }

  .courseImg {
    width: 100%;
    margin-right: 20px;
  }
  .courseContent {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
  }

  .anchor {
    width: 100%;
    height: auto;
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 2;
    background-color: #fff;
    border-bottom: 1px solid #e4e1e1;
    padding-left: 10%;
  }
}
