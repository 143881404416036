@media (min-width: 769px) {
  .mainFull {
    background-image: url(https://img.cbnlive.cn/cbnlive/www/image/bg.png);
    background-size: 100% 100%;
    margin-top: 70px;
  }
  .main {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
  .connect {
    align-items: center;
    text-align: center;
    height: 700px;
  }
  .weibo {
    width: 40px;
    height: 40px;
    margin-top: 150px;
  }
  .weixin {
    width: 40px;
    height: 40px;
    margin-bottom: 150px;
  }
}
