@media screen and (max-width: 768px) {
  .title {
    width: 33px;
    height: 9px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #31353b;
    line-height: 9px;
    margin-top: 64px;
    white-space: nowrap;
  }
  .content {
    display: flex;
    margin-top: 10px;
  }
  .content img {
    height: 12px;
    font-size: 10px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-weight: 500;
    color: #b1b1b1;
    line-height: 12px;
  }
  .content img:first-child {
    width: 34px;
    margin-right: 10px;
  }
  .content img:nth-child(2) {
    width: 44px;
    margin-right: 10px;
  }
  .content img:last-child {
    width: 50px;
  }
}

@media screen and (min-width: 769px) {
  .content {
    display: flex;
  }
  .content img {
    height: 24px;
  }
  .content img:first-child {
    width: 68px;
    margin-right: 20px;
  }
  .content img:nth-child(2) {
    width: 88px;
    margin-right: 20px;
  }
  .content img:last-child {
    width: 100px;
  }
}
