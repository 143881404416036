.title {
  width: 100px;
  height: 40px;
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.underLineShort {
  margin: 0 auto;
}

.underLineLong {
  height: 2px;
  background: #0000001f;
  margin: 0 auto;
}
