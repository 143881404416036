@media (max-width: 768px) {
  .main {
    margin-top: 40px;
  }
  .layout {
    padding: 0 10px;
  }
  .marginBottom {
    margin-bottom: 20px;
  }

  .desc {
    padding: 0 10px;
  }
  .descImg {
    width: 100%;
    padding: 0 10px;
  }

  .payImg {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* pc */
@media (min-width: 769px) {
  .layout {
    max-width: 1440px;
    width: 75%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .marginBottom {
    margin-bottom: 20px;
  }

  .descImg {
    height: 500px;
    float: left;
    margin-right: 50px;
  }
  .descContent {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 27px;
    /* margin-top: -5px; */
  }

  .pay {
    height: auto;
    background-color: #ffffff;
  }
  .payImg {
    width: 100%;
    margin-top: 20px;
    margin-right: 20px;
  }
  .payContent {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
  }
}
