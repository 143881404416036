/* mobile */
@media screen and (max-width: 768px) {
  .top {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #ffffff;
  }
  .topLeft {
    position: absolute;
    left: 12px;
    font-size: 12px;
  }
  .topRight {
    position: absolute;
    right: 0;
    display: flex;
  }
  .img {
    margin-right: 10px;
  }

  .copyright {
    padding: 10px;
    background-color: #6b81ff;
    text-align: center;
    line-height: 0px;
  }
}

/* pc */
@media screen and (min-width: 769px) {
  .footer {
    height: 300px;
    text-align: center;
    color: #fff;
    background-color: #6b81ff;
    padding: 0;
  }
  .layout {
    max-width: 1440px;
    margin: 0 auto;
  }

  .divider {
    padding: 0 0 50px 0;
    border-bottom: 1px solid #e4e1e1;
  }

  .copyright {
    font-size: 12px;
  }
}
