@media screen and (max-width: 768px) {
  .ant-carousel .slick-dots li button {
    background-color: black !important;
    margin: 0 0 20px 8.3333% !important;
  }
  .ant-collapse-header {
    padding: 9px 10px !important;
    font-size: 12px !important;
    background-color: #fff !important;
  }
  .ant-collapse-content-box {
    padding: 9px 10px !important;
    font-size: 12px !important;
    background-color: #fff !important;
  }
}
