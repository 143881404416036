.merchant {
  width: 80%;
  max-width: 1440px;
  margin: 50px auto;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

@media screen and (max-width: 767px) {
  /* 这里是针对手机端的样式 */
  .merchant h1 {
    width: 100%;
  }
  .merchant h2 {
    width: 100%;
  }
  .merchant p {
    width: 100%;
  }
  .merchant {
    width: 90%;
    margin: 20px auto;
  }
}

.headTable {
  border: 1px solid black;
}
