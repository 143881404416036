.privacy {
  width: 80%;
  max-width: 1440px;
  margin: 50px auto;
}
@media screen and (max-width: 767px) {
  /* 这里是针对手机端的样式 */
  .privacy h1 {
    width: 100%;
  }
  .privacy h2 {
    width: 100%;
  }
  .privacy p {
    width: 100%;
  }
  .privacy {
    width: 90%;
    margin: 20px auto;
  }
}
