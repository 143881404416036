@media screen and (max-width: 768px) {
  .menu-open {
    overflow: hidden;
  }
  .menu-open .Menu {
    transform: translateY(0);
  }
  .Menu {
    transform: translateY(-100%);
  }

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    line-height: 40px;
    background-color: #ffffff;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999;
  }
  .navLeft {
    position: absolute;
    left: 10px;
    width: 18px;
    height: 18px;
  }
  .navRight {
    width: 68px;
    height: 18px;
  }
  .navContent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999;
    margin-top: 40px;
    opacity: 0.4;
  }
}

@media screen and (min-width: 769px) {
  .headerStyle {
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    text-align: center;
    line-height: 70px;
    background-color: #fff;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #e4e1e1;
  }

  .img {
    box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.07);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin: 18px 0;
  }
}
